import { Section } from "@/components";
import { theme } from "@/styles";
import React, { PureComponent } from "react";

class ClientList extends PureComponent {
  render() {
    const { items } = this.props;
    return (
      <Section classNames="uk-text-center" style={{ minHeight: "auto" }}>
        <div className="uk-container uk-container-small">
          <div className="uk-h2 uk-text-center uk-margin-large-bottom">
            We’ve worked with:
          </div>
          <div className="uk-width-1-2@s uk-margin-auto">
            <div className="uk-grid uk-child-width-1-3@s" data-uk-grid>
              {items.map(({ name }, index) => (
                // @TODO:
                <div
                  key={index.toString()}
                  className="uk-text-lead uk-text-bold"
                  style={{ color: theme.colors.green }}
                >
                  {name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Section>
    );
  }
}

export default ClientList;

ClientList.defaultProps = {
  items: [
    {
      name: "4EE"
    },
    {
      name: "Melbourne University"
    },
    {
      name: "Impact.Co"
    },
    {
      name: "York Butter Factory"
    },
    {
      name: "Bring Me Home"
    },
    {
      name: "Digital Academy"
    }
  ]
};
