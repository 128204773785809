import { Section, Text } from "@/components";
import { theme } from "@/styles";
import styled from "@emotion/styled";
import { Link, StaticQuery, graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic-graphql";
import _ from "lodash";
import { linkResolver } from "prismic-configuration";
import React, { PureComponent } from "react";
import UIkit from "uikit";

const BreadcrumbItem = styled.li`
  &.uk-active > a {
    color: ${theme.colors.green};
  }
`;

const ProjectItem = ({ title, categories, _meta }) => {
  const tags = _.map(categories, "category.title").join(" ");

  return (
    <li
      key={title}
      data-tags={tags}
      style={{
        border: "1.5pt solid white",
        borderRadius: "10px",
        margin: "15px 15px",
        padding: "25px 5px",
      }}
    >
      <Link
        className="uk-h2"
        to={linkResolver(_meta)}
        style={{ fontSize: "1.2em" }}
      >
        <Text pure>{title}</Text>
      </Link>
    </li>
  );
};

class ProjectList extends PureComponent {
  filterDiv = React.createRef();

  componentDidMount() {
    const filter = UIkit.filter(this.filterDiv.current, {
      target: "#js-filter",
    });
    this.filter = filter;
  }

  componentWillUnmount() {
    if (this.filter) this.filter.$destroy();
  }

  renderFilters() {
    const docs = this.props.data.prismic.allCategorys.edges;
    if (!docs) return null;

    return (
      <ul className="uk-breadcrumb uk-flex-center uk-margin-large-bottom">
        <BreadcrumbItem
          data-uk-filter-control="[data-tags]"
          className="uk-active"
        >
          <a className="uk-text-lead uk-text-bold" href="#All">
            All
          </a>
        </BreadcrumbItem>
        {docs.map(({ node: category }, index) => (
          <BreadcrumbItem
            key={index.toString()}
            data-uk-filter-control={`[data-tags*='${category.title}']`}
          >
            <a
              className="uk-text-lead uk-text-bold"
              href={`#${category.title}`}
            >
              {category.title}
            </a>
          </BreadcrumbItem>
        ))}
      </ul>
    );
  }

  renderItems() {
    const docs = this.props.data.prismic.allProjects.edges;
    if (!docs) return null;
    return (
      <ul
        id="js-filter"
        className="uk-grid uk-child-width-1-2 uk-child-width-1-3@m uk-text-center"
        data-uk-grid
        style={{ marginLeft: "0", justifyContent: "center", width: "auto" }}
      >
        {docs.map(({ node: project }, index) => (
          <ProjectItem key={index.toString()} {...project} />
        ))}
      </ul>
    );
  }

  render() {
    return (
      <Section id="projects" style={{ minHeight: "auto" }}>
        <div className="uk-container uk-container-small">
          <div ref={this.filterDiv}>
            {this.renderFilters()}
            {this.renderItems()}
          </div>
        </div>
      </Section>
    );
  }
}

export const projectsQuery = graphql`
  query {
    prismic {
      allCategorys {
        edges {
          node {
            title
          }
        }
      }
      allProjects {
        edges {
          node {
            _meta {
              uid
              type
            }
            title
            thumbnail
            categories {
              category {
                ... on PRISMIC_Category {
                  title
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default (props) => (
  <StaticQuery
    query={`${projectsQuery}`}
    render={withPreview(
      (data) => (
        <ProjectList data={data} {...props} />
      ),
      projectsQuery
    )}
  />
);
