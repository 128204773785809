import { Button, Image, ScrollTo, Section, Text } from "@/components";
import { DataContext } from "@/pages/portfolios";
import { theme } from "@/styles";
import { linkResolver } from "prismic-configuration";
import { RichText } from "prismic-reactjs";
import React, { useContext } from "react";

const FeaturedWork = () => {
  const data = useContext(DataContext);

  return (
    <Section>
      <div className="uk-container uk-container-small">
        <div
          className="uk-grid uk-grid-small uk-flex-middle uk-child-width-1-2@s uk-margin-bottom"
          data-uk-grid
        >
          <div>
            <Image {...data.prismic.allProjects.edges[0].node.thumbnail} />
          </div>
          <div className="uk-text-right">
            <h3>
              Recently, we helped{" "}
              {RichText.asText(data.prismic.allProjects.edges[0].node.title)}
              <br />
              <Text>{data.prismic.allProjects.edges[0].node.excerpt}</Text>
            </h3>
            <Button
              to={linkResolver(data.prismic.allProjects.edges[0].node._meta)}
              color={theme.colors.green}
            >
              Learn More
            </Button>
          </div>
        </div>
      </div>
      <ScrollTo href="#projects" />
      <br />
    </Section>
  );
};

export default FeaturedWork;
