import { BoostYourBusiness } from "@/components/sections";
import {
  ClientList,
  FeaturedWork,
  ProjectList,
  Testimonial,
} from "@/components/sections/PortfolioPage";
import { graphql } from "gatsby";
import React from "react";

export const DataContext = React.createContext({});

export const query = graphql`
  {
    prismic {
      allProjects(tags: ["project", "featured"]) {
        edges {
          node {
            title
            excerpt
            thumbnail
            _meta {
              uid
              type
            }
          }
        }
      }
    }
  }
`;

const PortfolioPage = ({ data }) => (
  <DataContext.Provider value={data}>
    <FeaturedWork />
    <ProjectList />
    <ClientList />
    <Testimonial />
    <BoostYourBusiness />
  </DataContext.Provider>
);

PortfolioPage.query = query;

export default PortfolioPage;
