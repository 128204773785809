import { Section } from "@/components";
import { theme } from "@/styles";
import { ClassNames } from "@emotion/core";
import styled from "@emotion/styled";
import React, { PureComponent } from "react";

const DoubleQuote = styled.div`
  opacity: 0.6;
  color: ${theme.colors.green};
  user-select: none;
  line-height: 0.5;
`;

const TestimonialItem = ({ className, item: { quote, client } }) => (
  <ClassNames>
    {({ cx }) => (
      <blockquote
        className={cx(
          "uk-position-relative uk-height-medium uk-width-5-6@s",
          className
        )}
        style={{ height: "auto", marginBottom: "80px" }}
      >
        <div className="uk-position-top-left">
          <DoubleQuote className="uk-heading-xlarge uk-margin-remove">
            &ldquo;
          </DoubleQuote>
        </div>

        <div>
          <p className="uk-text-normal">{quote}</p>
          <footer className="uk-text-right">{client}</footer>

          <div className="uk-position-bottom-right">
            <DoubleQuote className="uk-heading-xlarge">&rdquo;</DoubleQuote>
          </div>
        </div>
      </blockquote>
    )}
  </ClassNames>
);

class Testimonial extends PureComponent {
  render() {
    const { items } = this.props;

    return (
      <Section style={{ marginBottom: "-120px" }}>
        <div className="uk-container uk-container-small">
          <div className="uk-h2 uk-text-center uk-margin-large-bottom">
            What our clients say
          </div>
          <div>
            <TestimonialItem item={items[0]} />
            <TestimonialItem className="uk-align-right@s" item={items[1]} />
          </div>
        </div>
      </Section>
    );
  }
}

export default Testimonial;

Testimonial.defaultProps = {
  items: [
    {
      quote:
        "Impact Co. engaged the Momentium team to assist with the design and implementation of a social media campaign for our incubator program - The Good Incubator. Raymond and the team were fantastic to work with. They consistently produced high quality output and were very proactive in ensuring that the project remained on foot. A job very well done.",
      client: "Ming Low - Founder of Impact.Co",
    },
    {
      quote:
        "There were a number of leads generated by the marketing campaign & high level of expression of interest in my pilot program, which was encouraging! Also the team’s invaluable advice to include a non-disclosure agreement in the pilot application process, something which had not occurred to me, was very useful & showed real initiative!",
      client: "Athy Giannopoulos - Founder of Digital Academy",
    },
  ],
};
